.mainWrapper{
    width: 100vw;
    height: 100vh;
    background-image: linear-gradient(#1F1F1F,#000000);
    justify-content: center;
    align-items: center;
    display: flex;
}

.subWrapper{
    width: 100%;
    height: 100%;
    max-width: 1450px;
    display: flex;
    flex-direction: row;
}

.body{
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;
    justify-content: center;
    align-items:flex-start;
}

.imageWrapper{
    display: flex;
    width: 50%;
    height: 100%;
    justify-content: center;
    align-items: center;
    position: relative;
}

.text1{
    color: white;
    font-family: var(--font-Anton);
    margin: 0;
    text-align: left;
}

.text2{
    color: white;
    font-family: var(--font-Annie);
    margin: 0;
    text-align: left;
}

.imageSocial{
    position: absolute;
    bottom: 15%;
    left: 50%;
    transform: translateX(-50%);
}

@media screen and (min-width:501px) and (max-width:1024px) {
    .mainWrapper{
        max-height: 900px;
        min-height: 850px;
    }

}

@media screen and (min-width:1025px){
    .mainWrapper{
        max-height: 900px;
        min-height: 850px;
    }

    .subWrapper{
        max-width: 2200px;
    }

    .image{
        width:600px;
        height: auto;
        object-fit: contain;
    }

    .imageSocial{
        width:350px;
        height: auto;
        object-fit: contain;
    }

    .text1{
        font-size: 100px;
    }

    .text2{
        font-size: 70px;
        max-width: 80%;
    }

    .body{
        padding-left: 150px;
    }
}