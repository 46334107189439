.mainWrapper{
    width: 100vw;
    height: 100vh;
    background-image: linear-gradient(#000000,#1F1F1F);
    justify-content: center;
    align-items: center;
    display: flex;
}

.subWrapper{
    width: 100%;
    height: 100%;
    max-width: 1450px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.titleWrapper{

}

.title{
    margin: 0;
    color: white;
    font-family: var(--font-Anton);
}

.caWrapper{
    background-color: #FF6868;
    align-self: center;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ca{
    margin: 0;
    color: white;
    font-family: var(--font-Anton);
    align-self: center;
}

.itemsWrapper{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    justify-content: center;
    align-items: center;
}

.itemText{
    color: white;
    font-family: var(--font-bangers);
}

.itemImg{

}

@media screen and (min-width:501px) and (max-width:1024px) {
    .mainWrapper{
        max-height: 900px;
        min-height: 850px;
    }

}

@media screen and (min-width:1025px){
    .mainWrapper{
        max-height: 900px;
        min-height: 850px;
    }

    .subWrapper{
        max-width: 2200px;
        gap: 75px;
    }

    .title{
        font-size: 120px;
    }

    .caWrapper{
        width: 700px;
        height: 100px;
    }

    .ca{
        font-size:30px;
    }

    .itemText{
        font-size: 50px;
    }
    
    .itemImg{
        width: 80px;
        height: auto;
        object-fit: contain;
    }
}