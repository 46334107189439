.mainWrapper{
    width: 100vw;
    height: 100vh;
    background-image: linear-gradient(#000000,#1F1F1F);
    justify-content: center;
    align-items: center;
    display: flex;
}

.subWrapper{
    width: 100%;
    height: 100%;
    max-width: 1450px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.image{
    height: auto;
    object-fit: contain;
    box-shadow: 5px 5px 15px black;
    transform: rotate(3deg);
}

@media screen and (min-width:501px) and (max-width:1024px) {
    .mainWrapper{
        max-height: 900px;
        min-height: 850px;
    }

}

@media screen and (min-width:1025px){
    .mainWrapper{
        max-height: 1250px;
        min-height: 1200px;
    }

    .subWrapper{
        max-width: 2200px;
    }

    .image{
        width: 800px;
    }

}