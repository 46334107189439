.mainWrapper{
    width: 100vw;
    height: 100vh;
    background-image: linear-gradient(#1F1F1F,#000000);
    justify-content: center;
    align-items: center;
    display: flex;
}

.subWrapper{
    width: 100%;
    height: 100%;
    max-width: 1450px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.coin{

}

.text1{
    color: white;
    font-family: var(--font-bangers);
    margin: 0;
}

.text2{
    color: white;
    font-family: var(--font-Annie);
    margin: 0;
}

@media screen and (min-width:501px) and (max-width:1024px) {
    .mainWrapper{
        max-height: 900px;
        min-height: 850px;
    }

}

@media screen and (min-width:1025px){
    .mainWrapper{
        max-height: 1150px;
        min-height: 1100px;
    }

    .subWrapper{
        max-width: 2200px;
        gap: 50px;
    }

    .coin{
        width: 500px;
        height: auto;
        object-fit: contain;
    }

    .text1{
        font-size: 70px;
        max-width: 75%;
    }

    .text2{
        font-size: 50px;
        max-width: 75%;
    }

}